var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.alertError.length > 0)?_c('v-alert',{staticClass:"text-pre-wrap",attrs:{"dense":"","border":"left","type":"warning"}},_vm._l((_vm.alertError),function(error,index){return _c('span',{key:index,staticClass:"d-block"},[_vm._v(_vm._s(error))])}),0):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.manualInvoiceDialog),callback:function ($$v) {_vm.manualInvoiceDialog=$$v},expression:"manualInvoiceDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Manual Invoice to HRDC ")]),_c('v-card-text',[_vm._v(" Are you sure to submit ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-light",attrs:{"color":"green lighten-1"},on:{"click":function($event){return _vm.modalManualInvoice()}}},[_vm._v(" Submit ")]),_c('v-btn',{staticClass:"text-light",attrs:{"color":"red lighten-1"},on:{"click":function($event){_vm.manualInvoiceDialog = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('v-card',[_c('v-toolbar',{attrs:{"color":"#063058"}},[(!_vm.loadingDialog)?_c('v-toolbar-title',[_c('div',{staticClass:"d-flex align-center text-white"},[_vm._v(" "+_vm._s(_vm.model.add.name)+" ")])]):_vm._e(),_c('v-spacer'),(_vm.hrdcData.isTaxpodAdminRoles && !_vm.btnDisabled)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"icon":"","color":"white","loading":_vm.apiSubmit.isLoading},on:{"click":function($event){return _vm.invoiceActions('Submit')}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-cloud-upload ")])],1)]}}],null,false,660938469)},[_c('span',[_vm._v("Upload")])])],1):_vm._e()],1),_c('div',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"w-100",on:{"drop":function($event){$event.preventDefault();return _vm.addDropFile($event)},"dragover":function($event){$event.preventDefault();}}},[_c('v-file-input',{attrs:{"prepend-icon":null,"prepend-inner-icon":"mdi-paperclip","counter":"","multiple":"","dense":"","filled":"","small-chips":"","clearable":"","show-size":1000,"label":"HRDC Invoice File","accept":".pdf","error-messages":_vm.errorField.multipleInvoice,"truncate-length":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){return _vm.deleteChip(index, text)}}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.formData.multipleInvoice),callback:function ($$v) {_vm.$set(_vm.formData, "multipleInvoice", $$v)},expression:"formData.multipleInvoice"}})],1)])],1),(_vm.completeAttachment.length > 0)?_c('v-col',[_c('v-alert',{attrs:{"outlined":"","type":"success","text":"","dismissible":""}},[_vm._v(" Success Uploaded: "),_c('br'),_vm._l((_vm.completeAttachment),function(item,i){return _c('div',{key:i},[_vm._v(_vm._s(item))])})],2)],1):_vm._e(),(_vm.failedAttachment.length > 0)?_c('v-col',[_c('v-alert',{attrs:{"outlined":"","type":"error","text":"","dismissible":""},on:{"input":function($event){return _vm.onAlertDismissed()}}},[_vm._v(" Failed Upload: "),_c('br'),_vm._l((_vm.failedAttachment),function(item,i){return _c('div',{key:i},[_vm._v("-"+_vm._s(item))])})],2)],1):_vm._e(),_c('v-row',{staticClass:"pa-5 d-flex"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-expansion-panels',{attrs:{"focusable":"","multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.formData.applicationManualInvoice),function(item,i){return _c('v-expansion-panel',{key:i,attrs:{"width":"500"}},[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(item.isDuplicate)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v(" mdi-alert-circle ")])]}}],null,true)},[_c('span',[_vm._v("Same company name detected")])]):(!item.isDuplicate)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" $expand ")]):_vm._e()]},proxy:true}],null,true)},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.companyName)+" ")])]),_c('v-expansion-panel-content',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_vm._v(" taxPOD Masterclass "),_c('br'),_vm._v(" Price: "+_vm._s(item.price)+" "),_c('br'),_vm._v(" Event Name: "+_vm._s(item.programmeName)+" "),_c('br'),_vm._v(" Date (Day): "+_vm._s(item.dateOfCourse)+" "),_c('br'),_vm._v(" Company Name: "+_vm._s(item.companyName)+" "),_c('br'),_vm._v(" Grant No: "+_vm._s(item.grantApprovalNo)+" "),_c('br')]),_c('v-col',{staticClass:"d-flex justify-content-between align-end flex-column",attrs:{"cols":"2"}},[(item.manualInvoiceToHrdcStatus)?_c('v-checkbox',{attrs:{"disabled":_vm.btnDisabled,"input-value":true},on:{"change":function($event){return _vm.changeInvoiceStatus(item.applicationUuid, $event)}}}):_c('v-checkbox',{attrs:{"disabled":_vm.btnDisabled},on:{"change":function($event){return _vm.changeInvoiceStatus(item.applicationUuid, $event)}}}),_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"color":"blue"},on:{"click":function($event){return _vm.copyContent(item)}}},[_vm._v("mdi-content-copy")])],1)],1)],1)],1)}),1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" Loading "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }